<template>
  <div class="footer-box">
    <!-- <div class="box-top">
            <div class="top-centent">
                <p>合作伙伴</p>
                <a class="top-imgdiv" v-for="(item,index) in imgArr " :key="index">
                    <img :src="item.imgurl" alt="">
                </a>
            </div>
        </div> -->
    <div class="box-btm">
      <div class="btm-centent">
        <div class="foot-logon"></div>
        <div class="text-div">
          <ul class="btm-textlink">
            <li><a href="" class="footer-a">网站首页</a></li>
            <li><a href="" class="footer-a">帮助中心</a></li>
            <li><a href="" class="footer-a">联系我们</a></li>
            <li><a href="" class="footer-a">招聘信息</a></li>
            <li><a href="" class="footer-a">客户服务</a></li>
            <li><a href="" class="footer-a">隐私政策</a></li>
            <li><a href="" class="footer-a">网站地图</a></li>
            <li><a href="" class="footer-a">意见反馈</a></li>
          </ul>
          <p>
            Copyright 2012-2020 www.dyyxclub.com 福州大扬摄影有限公司 版权所有
            闽ICP备14001401号
          </p>
        </div>
        <div class="phonum">
          <p class="photag"><i></i>客服热线</p>
          <p>0591-22850300</p>
          <p>0591-38700217</p>
        </div>
        <!-- <div class="footer-img">
          <div class="wx"></div>
          <div class="wb"></div>
          <div class="yj"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    mounted: function () {
      var _hmt = _hmt || [];
      (function () {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?30d91d3d48bfcd9357c77b0f1e4bcebf";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      })();
    },
  },
};
</script>
<style>
.footer-box {
  width: auto;
  height: 147px;
  margin-top: 25px;
}
.box-btm {
  width: auto;
  height: 100%;
  background: #fff;
}
.top-centent {
  width: 1200px;
  height: auto;
  margin: auto;
  padding-top: 25px;
  padding-bottom: 35px;
}
.top-centent p {
  width: 56px;
  height: 19px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: rgba(153, 153, 153, 1);
  margin-bottom: 20px;
}
.btm-centent {
  width: 1200px;
  height: 100%;
  margin: auto;
}
.top-imgdiv {
  display: inline-block;
  width: 116px;
  height: 49px;
  margin-right: 19px;
}
.top-imgdiv:nth-child(10) {
  margin-right: 0;
}
.btm-centent {
  display: flex;
  align-items: center;
}
.foot-logon {
  width: 80px;
  height: 80px;
  background: url("../assets/images/footertag.png");
}
.btm-textlink {
  display: flex;
  margin-left: 30px;
}
.btm-textlink > li {
  margin-right: 30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #999999;
}
.footer-a {
  color: #999999;
}
.text-div {
  max-width: 750px;
}
.text-div > p {
  margin-left: 30px;
  margin-top: 7px;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  color: rgba(204, 204, 204, 1);
}
.phonum {
  width: 130px;
  height: 60px;
  color: #cccccc;
  font-size: 15px;
  margin-left: 50px;
}
.phonum > p {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.photag > i {
  display: inline-block;
  margin-right: 10px;
  width: 16px;
  height: 16px;
  background: url("../assets/images/photag.png");
  background-size: 100%;
}
.footer-img {
  display: flex;
  margin-left: 30px;
}
.footer-img > div {
  width: 50px;
  height: 50px;
  margin-left: 14px;
}
.wx {
  background: url("../assets/images/footerimg1.png");
}
.wx:hover {
  background: url("../assets/images/footerimghover1.png");
}
.wb {
  background: url("../assets/images/footerimg2.png");
}
.wb:hover {
  background: url("../assets/images/footerimghover2.png");
}
.yj {
  background: url("../assets/images/footerimg3.png");
}
.yj:hover {
  background: url("../assets/images/footerimghover3.png");
}
</style>
