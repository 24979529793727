<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
}
</script>

<style lang="scss">
html,body{
  width:100%;
  height:100%;
}
body{
  font-size: 16px;
  background-color:#eff3f5;
}
#app{
  width:100%;
  height:100%;
}
a{
  color: #333;
}
a:hover{
  color: #333;
}
.wrap{
  width: 1200px;
  margin: 0 auto;
}
// 分页
.page-wrap{
    text-align: center;
    width: 100%;
    margin:20px auto;
}
.el-pager li,
.btn-next,
.btn-prev{
    padding: 0 4px!important;
    font-size: 13px;
    min-width: 35px!important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    line-height: 35px!important;
    height: 35px!important;
    margin: 0 5px;
    border: 1px solid #b6b6b6!important;
    border-radius: 3px;
    color: #b6b6b6!important;
    &:hover{
        border: 1px solid $colorMain!important;
        color: $colorMain!important;
    }
    &.active{
        background: $colorMain!important;
        border: 1px solid $colorMain!important;
        color: #fff!important;
        &+li{
            border-left: 1px solid #b6b6b6;
        }
    }
}
</style>
