import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    vuex_user:{}, //用户数据
  },
  getters:{
    
  },
  mutations: {
    //用户登入
    userLogin(state, payload) {
      state.vuex_user = payload;
    },
    //用户登出
    userLogout(state, payload) {
      state.vuex_user = null;
    }
  },
  actions: {
    //登录
    vuexUserLogin(context, payload) {
      context.commit('userLogin', payload);
      localStorage.setItem('vuex_user', JSON.stringify(payload));
    },
    //退出
    vuexUserLogout(context, payload) {
      context.commit('userLogout', null);
      localStorage.setItem('vuex_user', null);
    },
    //初始化本地user数据
    vuexInitLocalUser(context, payload) {
      let vuex_user = localStorage.getItem('vuex_user');
      if (vuex_user) {
        context.commit('userLogin', JSON.parse(vuex_user));
      }
    }
  },
  modules: {

  }
})
