<template>
    <div class="suspensioncom-box">
        <div class="top">
            <a id="gototop" href="javascript:void(0);" @click="goTop();return false;"></a>
        </div>
        <el-popover
            placement="left-end"
            trigger="hover"
        >
            <img class="qrCode-img" src="https://edu.dyyxclub.com/images/global-qrcode.png"/>
            <div class="qrCode" slot="reference"></div>
        </el-popover>
        <el-popover
            placement="left-end"
            trigger="hover"
        >
            <div class="service-box">
                <div class="hd z-flex z-flex-center"><i class="el-icon-s-comment"></i>大扬热线</div>
                <div class="bd">
                    <p>工作日：9:00-22:00</p>
                    <p>节假日：9:00-18:00</p>
                    <p>教学主管电话：19859117311</p>
                </div>
            </div>
            <div class="service" slot="reference"></div>
        </el-popover>
        <!-- <div class="LeaveMessage"></div> -->
    </div>
</template>
<script>
export default {
  data(){
    return{

    }
  },
  methods:{

    goTop(acceleration, time) {
        var timer  = null;
            cancelAnimationFrame(timer);
            timer = requestAnimationFrame(function fn(){
                var oTop = document.body.scrollTop || document.documentElement.scrollTop;
                if(oTop > 0){
                    document.body.scrollTop = document.documentElement.scrollTop = oTop - 150;
                    timer = requestAnimationFrame(fn);
                }else{
                    cancelAnimationFrame(timer);
                }
                });
    }
  }
}
</script>
<style lang="scss" scoped>
.suspensioncom-box{
    width: 69px;
    height: auto;
    position: fixed;
    bottom: 80px;
    right: 50px;
    z-index: 99;
}
.top{
    width: 69px;
    height: 69px;
    margin-bottom: 3px;
    background: url('../assets/images/icon-right1.png');
}
.top>a{
    display: inline-block;
    width: 100%;
    height: 100%;
}
.top:hover{
    background: url('../assets/images/icon-righthover1.png');
}
.qrCode{
    width: 69px;
    height: 69px;
    margin-bottom: 3px;
    background: url('../assets/images/icon-right2.png');
}
.qrCode:hover{
    background: url('../assets/images/icon-righthover2.png');
}
.service{
    width: 69px;
    height: 69px;
    margin-bottom: 3px;
    background: url('../assets/images/icon-right3.png');
}
.service:hover{
    background: url('../assets/images/icon-righthover3.png');
}
.LeaveMessage{
    width: 69px;
    height: 69px;
    margin-bottom: 3px;
    background: url('../assets/images/icon-right4.png');
}
.LeaveMessage:hover{
    background: url('../assets/images/icon-righthover4.png');
}
.qrCode-img{
    width: 150px;
    height: 150px;
}
.service-box{
    text-align: center;
    .hd{
        font-size: 15px;
        color:$colorMain;
        padding:3px 0 8px;
        border-bottom: $borderDotted;
        i{
            font-size: 18px;
            margin-right: 5px;
        }
    }
    .bd{
        padding:10px 0 0;
        p{
            margin: 5px 0;
        }
    }
}
</style>
