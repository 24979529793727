<template>
    <div class="layout">
        <navcom ref="navcom"></navcom>
        <router-view></router-view>
        <suspensioncom></suspensioncom>
        <footercom></footercom>
    </div>
</template>

<script>
    import navcom from "../components/nav"
    import suspensioncom from "../components/suspensioncom"
    import footercom from "../components/footercom"
    import {mapState, mapActions} from "vuex";

    export default {
        name: 'layout',
        components: {
            navcom,
            suspensioncom,
            footercom
        },
        data() {
            return {}
        },
        created() {
            this.vuexInitLocalUser();
            this.$axios.api.userinfo({}, (res) => {
                if (res.code == 0) {
                    let user = {
                        'user_id': res.data.id,
                        'nickname': res.data.nick_name,
                        'mobile': res.data.mobile,
                        'is_supreme': res.data.is_supreme,
                        'is_role': res.data.is_role,
                        'avatar': res.data.avatar
                    };
                    this.vuexUserLogin(user)
                } else {
                    this.vuexUserLogout(null)
                }
            });
        },
        computed: {
            ...mapState(['vuex_user'])
        },
        methods: {
            ...mapActions(['vuexUserLogin', 'vuexUserLogout', 'vuexInitLocalUser']),
        }
    }
</script>

<style lang="scss">
    .layout {
        width: 100%;
        height: 100%;
    }

    .el-menu--popup-bottom-start {
        min-width: 110px;
    }

    .el-menu-item:not(.is-disabled):hover {
        background: rgba(230, 242, 255, 1);;
    }

    .el-menu--horizontal {
        border-top: 5px solid #fff;
    }

    .el-menu--horizontal > .el-submenu .el-submenu__title {
        border-bottom: none;
        border-top: 5px solid transparent;
    }

    .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
        border-bottom: none;
        border-top: 5px solid #409EFF;
    }
</style>
