import Vue from 'vue'
import Router from 'vue-router'
import layout from '@/components/layout.vue'

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: '/index',
    redirect: '/index',
    component: layout,
    children:[
      {
        path: '/index',
        meta: {
          title: '大扬学院',
        },
        component: ()=>import('@/views/index.vue')
      },
      {
        path: '/list',
        meta: {
          title: '大扬学院',
        },
        component: ()=>import('@/views/list.vue')
      },
      {
        path: '/searchResult',
        meta: {
          title: '大扬学院',
        },
        component: ()=>import('@/views/searchResult.vue')
      },
      {
        path: '/course/:id',
        meta: {
          title: '大扬学院',
        },
        component: ()=>import('@/views/course.vue')
      },
      {
        path: '/video/:id',
        meta: {
          title: '大扬学院',
        },
        component: ()=>import('@/views/video.vue')
      },
      {
        path: '/clubs',
        meta: {
          title: '大扬学院',
        },
        component: ()=>import('@/views/clubs.vue')
      },
      {
        path: '/downloadAcr',
        meta: {
          title: '大扬学院',
        },
        component: ()=>import('@/views/downloadAcr.vue')
      },
      {
        path: '/downloadSoftware',
        meta: {
          title: '大扬学院',
        },
        component: ()=>import('@/views/downloadSoftware.vue')
      },
      {
        path: '/downloadMaterial',
        meta: {
          title: '大扬学院',
        },
        component: ()=>import('@/views/downloadMaterial.vue')
      },
      {
        path: '/downDetail/:id',
        meta: {
          title: '大扬学院',
        },
        component: ()=>import('@/views/downDetail.vue')
      },
      {
        path: '/help/:id',
        meta: {
          title: '大扬学院',
        },
        component: ()=>import('@/views/help.vue')
      },
      {
        path: '/activities',
        meta: {
          title: '大扬学院',
        },
        component: ()=>import('@/views/activities.vue')
      },
    ]
  },
]

const router = new Router({
  mode: 'hash',
  routes,
  scrollBehavior (to, from, savedPosition) {
    // if (savedPosition) {
    //   return savedPosition
    // } else {
    //   return { x: 0, y: 0 }
    // }
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  // 设置标题
  if (to.meta && to.meta.title) {
    window.document.title = to.meta.title;
  }

  next();
});

export default router
