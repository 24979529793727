<template>
  <div class="passport" v-if="showPassport">
    <div class="mask" @click="closePassport"></div>
    <!-- 密码登录 -->
    <div class="box" v-if="show=='loginPswd'">
      <div class="hd">密码登录</div>
      <div class="bd">
        <form class="form" action="">
          <div class="item item-ipt">
            <select class="areaCode" v-model="form.areaCode">
              <option v-for="(item,index) in codeList" :key="index" :value="item.id">{{item.name}}</option>
            </select>
            <input class="ipt item-ipt" type="tel" placeholder="请输入手机号" v-model.trim="form.mobile"/>
          </div>
          <div class="item item-ipt">
            <input class="ipt" type="password" placeholder="请输入密码" v-model.trim="form.password"/>
          </div>
          <div class="item">
            <button type="button" 
              :class="['btn',submitLoding?'btn-loading':'',form.mobile==''||form.password==''?'disabled':'']"
              @click="pwdLogin"
            >
              {{submitLoding?'提交中...':'登 录'}}
            </button>
          </div>
        </form>
        <div class="form-bottom">
          <a href="javascript:void(0)" @click="show='findPswd';resetForm()">忘记密码？</a>
          <a href="javascript:void(0)" @click="show='register';resetForm()">注册新账号</a>
        </div>
        <div class="others">
          <div class="others-hd"><span>其他登录方式</span></div>
          <div class="others-bd">
            <div class="others-bd-item" @click="wechatLogin">
              <div class="icon-wrap wechat"><i class="iconfont">&#xe615;</i></div>
            </div>
            <div class="others-bd-item" @click="show='loginSmscode';resetForm()">
              <div class="icon-wrap smscode"><i class="iconfont">&#xe600;</i></div>
            </div>
          </div>
        </div>
        <div class="tips">登录即代表你同意<a style="color:#1989fa" href="">《用户协议》</a></div>
      </div>
      <a class="close" href="javascript:void(0)" @click="closePassport"></a>
    </div>
    <!-- 密码登录 END -->
    <!-- 手机验证码登录 -->
    <div class="box" v-if="show=='loginSmscode'">
      <div class="hd">手机验证码登录</div>
      <div class="bd">
        <form class="form" action="">
          <div class="item item-ipt">
            <select class="areaCode" v-model="form.areaCode">
              <option v-for="(item,index) in codeList" :key="index" :value="item.id">{{item.name}}</option>
            </select>
            <input class="ipt item-ipt" type="tel" placeholder="请输入手机号" v-model.trim="form.mobile"/>
          </div>
          <div class="item item-ipt">
            <input class="ipt item-ipt" type="text" placeholder="请输入验证码" maxlength="6" v-model.trim="form.smsCode"/>
            <a :class="['btn-sendsms',sendSmsLoding?'btn-sendsms-loading':'']" href="javascript:void(0)" @click="sendSms('login')">
              <span v-if="sendSmsDown==0">获取验证码</span>
              <span v-else>{{sendSmsDown}}s后重新获取</span>
            </a>
          </div>
          <div class="item">
            <button type="button" 
              :class="['btn',submitLoding?'btn-loading':'',form.mobile==''||form.smsCode==''?'disabled':'']"
              @click="mobileLogin"
            >
              {{submitLoding?'提交中...':'登 录'}}
            </button>
          </div>
        </form>
        <div class="form-bottom">
          <a href="javascript:void(0)" @click="show='findPswd';resetForm()">忘记密码？</a>
          <a href="javascript:void(0)" @click="show='register';resetForm()">注册新账号</a>
        </div>
        <div class="others">
          <div class="others-hd"><span>其他登录方式</span></div>
          <div class="others-bd">
            <div class="others-bd-item" @click="wechatLogin">
              <div class="icon-wrap wechat"><i class="iconfont">&#xe615;</i></div>
            </div>
            <div class="others-bd-item" @click="show='loginPswd';resetForm()">
              <div class="icon-wrap password"><i class="iconfont">&#xe62a;</i></div>
            </div>
          </div>
        </div>
        <div class="tips">登录即代表你同意<a style="color:#1989fa" href="">《用户协议》</a></div>
      </div>
      <a class="close" href="javascript:void(0)" @click="closePassport"></a>
    </div>
    <!-- 手机验证码登录 END -->
    <!-- 微信二维码登录 -->
    <div class="box" v-if="show=='loginWechat'">
      <div class="hd">微信登录</div>
      <div class="bd">
        <div class="qrcode-wrap">
          <div id="login_container"><img class="qrcode" :src="qrcode"/></div>
        </div>
        <div class="others">
          <div class="others-hd"><span>其他登录方式</span></div>
          <div class="others-bd">
            <div class="others-bd-item" @click="show='loginSmscode';resetForm()">
              <div class="icon-wrap smscode"><i class="iconfont">&#xe600;</i></div>
            </div>
            <div class="others-bd-item" @click="show='loginPswd';resetForm()">
              <div class="icon-wrap password"><i class="iconfont">&#xe62a;</i></div>
            </div>
          </div>
        </div>
        <div class="tips">登录即代表你同意<a style="color:#1989fa" href="">《用户协议》</a></div>
      </div>
      <a class="close" href="javascript:void(0)" @click="closePassport"></a>
    </div>
    <!-- 微信二维码 END -->
    <!-- 忘记密码 -->
    <div class="box" v-if="show=='findPswd'">
      <div class="hd">忘记密码</div>
      <div class="bd">
        <form class="form" action="">
          <div class="item item-ipt">
            <select class="areaCode" v-model="form.areaCode">
              <option v-for="(item,index) in codeList" :key="index" :value="item.id">{{item.name}}</option>
            </select>
            <input class="ipt item-ipt" type="tel" placeholder="请输入手机号" v-model.trim="form.mobile"/>
          </div>
          <div class="item item-ipt">
            <input class="ipt item-ipt" type="text" placeholder="请输入验证码" maxlength="6" v-model.trim="form.smsCode"/>
            <a :class="['btn-sendsms',sendSmsLoding?'btn-sendsms-loading':'']" href="javascript:void(0)" @click="sendSms('password_reset')">
              <span v-if="sendSmsDown==0">获取验证码</span>
              <span v-else>{{sendSmsDown}}s后重新获取</span>
            </a>
          </div>
          <div class="item item-ipt">
            <input class="ipt" type="password" placeholder="设置密码 6-12位字母/数字/符号" v-model.trim="form.password"/>
          </div>
          <div class="item item-ipt">
            <input class="ipt" type="password" placeholder="再次确认密码" v-model.trim="form.password2"/>
          </div>
          <div class="item">
            <button type="button" 
              :class="['btn',submitLoding?'btn-loading':'',form.mobile==''||form.smsCode==''||form.password==''||form.password2==''?'disabled':'']"
              @click="resetPswd"
            >
              {{submitLoding?'提交中...':'完 成'}}
            </button>
          </div>
        </form>
      </div>
      <a class="close" href="javascript:void(0)" @click="closePassport"></a>
    </div>
    <!-- 忘记密码 END -->
    <!-- 注册账号 -->
    <div class="box" v-if="show=='register'">
      <div class="hd">注册账号</div>
      <div class="bd">
        <form class="form" action="">
          <div class="item item-ipt">
            <input class="ipt item-ipt" type="text" placeholder="昵称暂不支持修改，请慎重取名哦" v-model.trim="form.nickname"/>
          </div>
          <div class="item item-ipt">
            <select class="areaCode" v-model="form.areaCode">
              <option v-for="(item,index) in codeList" :key="index" :value="item.id">{{item.name}}</option>
            </select>
            <input class="ipt item-ipt" type="tel" placeholder="请输入手机号" v-model.trim="form.mobile"/>
          </div>
          <div class="item item-ipt">
            <input class="ipt item-ipt" type="text" placeholder="请输入验证码" maxlength="6" v-model.trim="form.smsCode"/>
            <a :class="['btn-sendsms',sendSmsLoding?'btn-sendsms-loading':'']" href="javascript:void(0)" @click="sendSms('register')">
              <span v-if="sendSmsDown==0">获取验证码</span>
              <span v-else>{{sendSmsDown}}s后重新获取</span>
            </a>
          </div>
          <div class="item item-ipt">
            <input class="ipt" type="password" placeholder="设置密码 6-12位字母/数字/符号" v-model.trim="form.password"/>
          </div>
          <div class="item">
            <button type="button" 
              :class="['btn',submitLoding?'btn-loading':'',form.nickname==''||form.mobile==''||form.smsCode==''||form.password==''?'disabled':'']"
              @click="register"
            >
              {{submitLoding?'提交中...':'同意协议并注册'}}
            </button>
          </div>
        </form>
        <div class="form-bottom">
          <div>阅读<a style="color:#1989fa" href="">《用户协议》</a></div>
          <div>已有账号，<a style="color:#1989fa" href="javascript:void(0)" @click="show='loginPswd';resetForm()">马上登录</a></div>
        </div>
      </div>
      <a class="close" href="javascript:void(0)" @click="closePassport"></a>
    </div>
    <!-- 注册账号 END -->
    <!-- 绑定账号 -->
    <div class="box" v-if="show=='wechatBind'">
      <div class="hd">绑定账号</div>
      <div class="bd">
        <form class="form" action="">
          <div class="item item-ipt">
            <input class="ipt item-ipt" type="text" disabled placeholder="昵称暂不支持修改，请慎重取名哦" v-model.trim="form.nickname"/>
          </div>
          <div class="item item-ipt">
            <select class="areaCode" v-model="form.areaCode">
              <option v-for="(item,index) in codeList" :key="index" :value="item.id">{{item.name}}</option>
            </select>
            <input class="ipt item-ipt" type="tel" placeholder="请输入手机号" v-model.trim="form.mobile"/>
          </div>
          <div class="item item-ipt">
            <input class="ipt item-ipt" type="text" placeholder="请输入验证码" maxlength="6" v-model.trim="form.smsCode"/>
            <a :class="['btn-sendsms',sendSmsLoding?'btn-sendsms-loading':'']" href="javascript:void(0)" @click="sendSms('mobile_bind')">
              <span v-if="sendSmsDown==0">获取验证码</span>
              <span v-else>{{sendSmsDown}}s后重新获取</span>
            </a>
          </div>
          <div class="item">
            <button type="button" 
              :class="['btn',submitLoding?'btn-loading':'',form.mobile==''||form.smsCode==''?'disabled':'']"
              @click="wechatBind"
            >
              {{submitLoding?'提交中...':'绑定登录'}}
            </button>
          </div>
        </form>
        <div class="form-bottom">
          <div>阅读<a style="color:#1989fa" href="">《用户协议》</a></div>
          <div>已有账号，<a style="color:#1989fa" href="javascript:void(0)" @click="show='loginPswd';resetForm()">马上登录</a></div>
        </div>
      </div>
      <a class="close" href="javascript:void(0)" @click="closePassport"></a>
    </div>
    <!-- 绑定账号 END -->
    <!-- toast -->
    <div class="toast" v-if="showToast">
      {{toastText}}
    </div>
    <!-- toast END -->
  </div>
</template>
<script>
import VueCookies from "vue-cookies";

export default {
  name: "passport",
  props: {
    onLogin: Function,
    onLogout: Function,
    wechatOfficialAppid: String,
    wechatOpenAppid: String,
    siteUrl: String //服务端域名：http://edu.test.dyyxclub.com/
  },

  data() {
    return {
      showToast:false,
      toastText:'',
      submitLoding:false,
      sendSmsLoding:false,
      sendSmsDown: 0,//倒计时
      timerScaler: false,//定时器对象
      showPassport:false,
      show:'loginSmscode',
      form:{
        unionid:'',//微信唯一id
        nickname:'',//昵称
        avatar:'',//头像
        areaCode:'+86',// 区号
        mobile:'',//手机号
        password:'',//密码
        password2:'',//确认密码
        captcha:'',//图形验证码
        smsCode:'',//短信验证码
      },
      codeList: [
        {id: '+86', name: '+86'},
        {id: '+852', name: '+852'},
        {id: '+886', name: '+886'}
      ],
      qrcode:'https://www.pai2345.com/static/web/res/images/code.png',//二维码
    };
  },
  watch: {
    
  },
  mounted(){
    // 扫码登录成功
    window.addEventListener('message', (event) => {
      // console.log("addEventListener:", event)
      if (event.data && event.data.action == 'snsapi_login') {
        if (event.data.status == '1') {
          //扫码成功，进行手机绑定
          this.show = 'wechatBind';
          this.resetForm();
          this.form.nickname = event.data.data.nickname;
          this.form.unionid = event.data.data.unionid;
          this.form.avatar = event.data.data.avatar;
          this.showPassport = true;
        } else if (event.data.status == '2') {
          //登录成功
          this.loginSuccess(event.data.data);
        } else {
          this.toast(event.data.msg);
        }
      }
    }, false)
    //监控当前页面
    const wechat_bind_data = VueCookies.get('wechat_bind_data');
    if (wechat_bind_data) {
      //进行手机绑定
      this.show = 'wechatBind';
      this.resetForm();
      this.form.nickname = wechat_bind_data.nickname;
      this.form.unionid = wechat_bind_data.unionid;
      this.form.avatar = wechat_bind_data.avatar;
      this.showPassport = true;
      document.cookie = "wechat_bind_data=;path=/;domain=dyyxclub.com;expires=" + new Date(0).toUTCString();
    }
  },
  methods: {
    //消息提示
    toast (text) {
      this.toastText = text;
      this.showToast = true;
      setTimeout(()=>{
        this.showToast = false
      }, 1500)
    },
    closePassport(){
      this.showPassport = false;
      this.show = 'loginPswd';
      this.resetForm();
    },
    //重置表单
    resetForm(){
      Object.keys(this.form).forEach((key) => (this.form[key] = ''));
      this.$set(this.form,'areaCode','+86');
    },
    //短信倒计时
    sendSmsScaler(){
      if (this.sendSmsDown > 0) {
        this.sendSmsDown-=1;
        setTimeout((res) => {
          this.sendSmsScaler()
        }, 1000)
      } else {
        clearTimeout(this.timerScaler);
      }
    },
    //获取验证码
    sendSms(scene){
      if (!this.form.mobile) {
        return this.toast("请输入手机号码");
      }
      if (this.sendSmsLoding) {
        return console.log("正在发送短信")
      }
      if (this.sendSmsDown > 0) {
        return console.log("短信CD冷却中。")
      }
      this.sendSmsLoding = true;
      this.$axios.post(this.siteUrl + "/api/v2/captcha/sms2", {
        scene: scene,
        code: this.form.areaCode,
        mobile: this.form.mobile
      }).then((res) => {
        if (res.data.code == 0) {
          // this.toast("短信发送成功");
          //发送成功,开启倒计时
          this.sendSmsDown = 60;
          this.sendSmsScaler();
        } else {
          this.toast("短信发送失败");
        }
        this.sendSmsLoding = false;
      }).catch((error) => {
        console.log("短信验证码发送失败");
        this.sendSmsLoding = false;
      });
    },
    //微信登录
    wechatLogin(){
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if(ua.match(/MicroMessenger/i) == 'micromessenger') {
        //微信浏览器
        var appid = this.wechatOpenAppid;
        var redirect_uri = encodeURIComponent(window.location.href);
        //记录当前地址
        var exp = new Date();
        exp.setTime(exp.getTime() + 86400 * 1000);
        document.cookie = "wechat_redirect_uri="+redirect_uri+";path=/;domain=dyyxclub.com;expires=" + exp.toUTCString();
        //授权成功后回调地址
        redirect_uri = encodeURIComponent(this.siteUrl + "/api/v2/wechat/authBrowser");
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_userinfo&state=wechat#wechat_redirect";
      } else {
        //H5浏览器
        this.show = 'loginWechat';
        this.$nextTick(()=>{
          var obj = new WxLogin({
            self_redirect:true,
            id:"login_container",
            appid: this.wechatOfficialAppid,
            scope: "snsapi_login",
            redirect_uri: encodeURIComponent(this.siteUrl + "/api/v2/wechat/authCallback"),
            state: "8f6397e1e156",
          });
        })
      }
    },
    //注册
    register(){
      if (this.submitLoding) {
        return;
      }
      this.submitLoding = true;
      this.$axios.post(this.siteUrl + "/api/v2/passport/register", {
        code: this.form.areaCode,
        mobile: this.form.mobile,
        sms_code: this.form.smsCode,
        password: this.form.password,
        nick_name: this.form.nickname
      }).then((res) => {
        if (res.data.code == 0) {
          this.toast("注册成功")
          this.show = 'loginPswd';
          this.resetForm();
        } else {
          this.toast(res.data.message)
        }
        this.submitLoding = false;
      }).catch((error) => {
        console.log("请求异常");
        this.submitLoding = false;
      });
    },
    //手机短信登录
    mobileLogin(){
      if (this.submitLoding) {
        return;
      }
      this.submitLoding = true;
      this.$axios.post(this.siteUrl + "/api/v2/passport/mobile", {
        code: this.form.areaCode,
        mobile: this.form.mobile,
        sms_code: this.form.smsCode
      }).then((res) => {
        if (res.data.code == 0) {
          this.loginSuccess(res.data.data)
        } else {
          this.toast(res.data.message)
        }
        this.submitLoding = false;
      }).catch((error) => {
        console.log("请求异常");
        this.submitLoding = false;
      });
    },
    //微信绑定账号
    wechatBind(){
      if (this.submitLoding) {
        return;
      }
      this.submitLoding = true;
      this.$axios.post(this.siteUrl + "/api/v2/passport/wechatBind", {
        code: this.form.areaCode,
        unionid: this.form.unionid,
        avatar: this.form.avatar,
        nickname: this.form.nickname,
        mobile: this.form.mobile,
        sms_code: this.form.smsCode
      }).then((res) => {
        if (res.data.code == 0) {
          this.loginSuccess(res.data.data)
        } else {
          this.toast(res.data.message)
        }
        this.submitLoding = false;
      }).catch((error) => {
        console.log("请求异常");
        this.submitLoding = false;
      });
    },
    //找回密码
    resetPswd(){
      if (this.submitLoding) {
        return;
      }
      if (this.form.password != this.form.password2) {
        return this.toast("两次密码不一致")
      }
      this.submitLoding = true;
      this.$axios.post(this.siteUrl + "/api/v2/passport/pwdReset", {
        code: this.form.areaCode,
        sms_code: this.form.smsCode,
        password: this.form.password
      }).then((res) => {
        if (res.data.code == 0) {
          this.toast("密码修改成功")
          this.show = 'loginPswd';
          this.resetForm();
        } else {
          this.toast(res.data.message)
        }
        this.submitLoding = false;
      }).catch((error) => {
        console.log("请求异常");
        this.submitLoding = false;
      });
    },
    //账号密码登录
    pwdLogin(){
      if (this.submitLoding) {
        return;
      }
      this.submitLoding = true;
      this.$axios.post(this.siteUrl + "/api/v2/passport/pwdlogin", {
        code: this.form.areaCode,
        mobile: this.form.mobile,
        password: this.form.password
      }).then((res) => {
        if (res.data.code == 0) {
          this.loginSuccess(res.data.data)
        } else {
          this.toast(res.data.message)
        }
        this.submitLoding = false;
      }).catch((error) => {
        console.log("请求异常");
        this.submitLoding = false;
      });
    },
    //登录成功，回调
    loginSuccess(data){
      // console.log('loginSuccess', data)
      var exp = new Date();
      exp.setTime(exp.getTime() + 14 * 86400 * 1000);
      document.cookie = "access_token="+data.token+";path=/;domain=dyyxclub.com;expires=" + exp.toUTCString();
      this.showPassport = false;
      this.$emit('onLogin',data);
    },
    //登出，回调
    userLogout(){
      document.cookie = "access_token=;path=/;domain=dyyxclub.com;expires=" + new Date(0).toUTCString();
      this.$emit('onLogout');
    },
  },
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: 'iconfont';  /* Project id 3305175 */
  src: url('//at.alicdn.com/t/font_3305175_fy0r8o5ynpo.woff2?t=1651807174499') format('woff2'),
       url('//at.alicdn.com/t/font_3305175_fy0r8o5ynpo.woff?t=1651807174499') format('woff'),
       url('//at.alicdn.com/t/font_3305175_fy0r8o5ynpo.ttf?t=1651807174499') format('truetype');
}
.iconfont{
  font-family:"iconfont" !important;
  font-style:normal;
}
.passport{
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2147483644;
  display: flex;
  justify-content: center;
  align-items: center;
  .mask{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2147483643;
  }
  .box{
    width: 85%;
    max-width: 400px;
    position: relative;
    background: #fff;
    z-index: 2147483644;
    border-radius: 8px;
    .hd{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      height: 60px;
      font-size: 18px;
      box-shadow: 0 0 5px 2px rgb(0 0 0 / 5%);
    }
    .bd{
      padding: 10px 25px 20px;
      .item{
        margin: 12px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #999;
        position: relative;
        .ipt{
          width: 100%;
          border: none;
          outline: 0;
          -webkit-appearance: none;
          padding: 8px 0;
          font-size: 16px;
          color: #333;
          flex: 1;
        }
        select{
          font-size: 16px;
          border: none;
          outline: 0;
          background: transparent;
          &.areaCode{
            margin-right: 10px;
          }
        }
        &.item-ipt{
          border-bottom: 1px solid #eee;
        }
        .captcha{
          height:30px;
          width:auto;
          display: inline-block;
          margin-left: 10px;
        }
        .btn-sendsms{
          display: inline-block;
          padding-left: 10px;
          color: #2693EF;
          font-size: 16px;
          &.btn-sendsms-loading{
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }
      .btn{
        background: #2693EF;
        border: none;
        color: #fff;
        border-radius: 5px;
        font-size: 16px;
        width: 100%;
        height: 46px;
        padding: 0;
        margin: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:active{
          opacity: .85;
        }
        &.disabled{
          background: #c2c2c2!important;
          border: none!important;
          pointer-events: none;
          cursor: not-allowed;
        }
        &.btn-loading{
          opacity: .7;
          cursor: not-allowed;
          pointer-events: none;
        }
      }
      .form-bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #666;
        a{
          color: #666;
        }
      }
      .others{
        .others-hd{
          text-align: center;
          color: #666;
          margin: 15px 0;
          position: relative;
          span{
            position: relative;
            z-index: 1;
            background-color: #fff;
            padding:0 15px;
          }
          &::after{
            content: " ";
            width: 100%;
            height:1px;
            display: block;
            background-color: #eee;
            position: absolute;
            left: 0;
            top: 50%;
            z-index: 0;
          }
        }
        .others-bd{
          display: flex;
          justify-content: center;
          align-items: center;
          .others-bd-item{
            margin:0 18px;
            .icon-wrap{
              width:46px;
              height:46px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              &.wechat{
                background-color: #07c160;
              }
              &.smscode{
                background-color: #ed6a0c;
              }
              &.password{
                background-color: #444;
              }
              .iconfont{
                color: #fff;
                font-size: 24px;
              }
            }
          }
        }
      }
    }
    .tips{
      text-align: center;
      color: #999;
      margin:15px 0 10px;
    }
    .close{
      position: absolute;
      right: 10px;
      top: 10px;
      width: 30px;
      height: 30px;
      display: block;
      background: url('https://edu.dyyxclub.com/images/close.png') no-repeat center;
      background-size: 18px 18px;
    }
    .qrcode-wrap{
      text-align: center;
      .qrcode{
        width: 100%;
        height:auto;
      }
      p{
        font-size: 16px;
        color: #999;
        margin: 10px 0;
      }
    }
  }
}
.toast {
  position: fixed;
  z-index: 2147483645;
  left: 50%;
  top:50%;
  text-align: center;
  transform:translate(-50%,-50%);
  border-radius: 5px;
  color:#FFF;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 15px;
  max-width: 320px;
}
</style>
