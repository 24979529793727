<template>
    <div class="nav-box">
        <div class="nav-content">
            <div class="nav-logo" @click="toindex"></div>
            <el-menu
                    class="el-menu-demo"
                    default-active="/index"
                    mode="horizontal"
                    :unique-opened="true"
                    @select="handleSelect"
            >
                <el-menu-item index="https://www.dyyxclub.com/dist/#/home">首页</el-menu-item>
                <el-menu-item index="/web" class="cur">大扬学院</el-menu-item>
                <el-menu-item index="https://www.dyyxclub.com/dist/#/artist">摄影采风</el-menu-item>
                <el-menu-item index="https://www.dyyxclub.com/dist/#/images">图片精修</el-menu-item>
                <el-menu-item index="https://www.dyyxclub.com/dist/#/film">影赛投稿</el-menu-item>
                <el-menu-item index="https://www.dyyxclub.com/dist/#/shop">大扬商城</el-menu-item>
                <el-menu-item index="https://www.dyyxclub.com/dist/#/moreHonor">获奖资讯</el-menu-item>
                <el-submenu index="5">
                    <template slot="title">关于大扬</template>
                    <el-menu-item index="https://www.dyyxclub.com/dist/#/teacher">大扬名师</el-menu-item>
                    <el-menu-item index="https://www.dyyxclub.com/dist/#/dyteam">大扬团队</el-menu-item>
                    <el-menu-item index="https://www.dyyxclub.com/dist/#/dynamic">大扬动态</el-menu-item>
                    <el-menu-item index="https://www.dyyxclub.com/dist/#/contactUs">联系我们</el-menu-item>
                </el-submenu>
            </el-menu>
            <div class="search">
                <el-input placeholder="请输入关键字进行搜索" v-model="keywords" clearable>
                    <el-button slot="append" icon="el-icon-search" @click="searchResult"></el-button>
                </el-input>
            </div>
            <div class="login">
                <a class="download vip" href="javascript:void(0)" @click="toDown">
                    <img src="../assets/images/20220518/i-download.png">
                    <p>下载中心</p>
                </a>
                <a class="vip" href="javascript:void(0)" @click="toVip">
                    <img src="../assets/images/vip.png">
                    <p>会员中心</p>
                </a>
                <el-button class="btn-login" type="primary" size="medium" round @click="login" v-if="!vuex_user">登 录
                </el-button>
                <!-- 已登录 -->
                <div class="user-wrap" v-if="vuex_user">
                    <div class="username-wrap" @click="openUserCenter">
                        <img class="avatar" :src="vuex_user.avatar">
                        <span class="username">{{ vuex_user.nickname }}</span>
                    </div>
                    <span class="break-line" style="margin: 0 5px 0 13px;">|</span>
                    <el-link :underline="false" @click="logout">退出</el-link>
                </div>
            </div>
        </div>
        <!-- passport -->
        <passport ref="passport" :siteUrl="passportSite" :wechatOfficialAppid="wechatOfficialAppid" :wechatOpenAppid="wechatOpenAppid" @onLogin="passportLogin" @onLogout="passportLogout" ></passport>
        <!-- passport EDN -->
    </div>
</template>
<script>
    import {mapState, mapActions} from "vuex";
    import passport from "./passport";
    export default {
        data() {
            return {
                passportSite: process.env.VUE_APP_URL,
                wechatOfficialAppid: process.env.VUE_APP_WECHAT_OFFICIAL_APPID,
                wechatOpenAppid: process.env.VUE_APP_WECHAT_OPEN_APPID,
                keywords: '',//搜索关键字
                isnNavShow: true,
                tagshow: true,
                inputvalue: "",
                restaurants: [],
                pathaction: "",
            };
        },
        components: {
            passport,
        },
        computed: {
            ...mapState(['vuex_user'])
        },
        created() {

        },
        mounted() {
            this.pathaction = window.location.pathname;
        },
        methods: {
            ...mapActions(['vuexUserLogin', 'vuexUserLogout', 'vuexInitLocalUser']),
            openUserCenter() {
                window.location.href = process.env.VUE_APP_WWW_URL + 'dist/#/usercenter';
            },
            searchResult() {
                // this.$router.push({
                //     path: '/searchResult?keywords=' + this.keywords
                // })
                this.$wwwRouter.push('searchResult?keywords=' + this.keywords);
            },
            isnNavShowfun() {
                this.tagshow = false;
                this.isnNavShow = !this.isnNavShow;
            },
            handleSelect(key, keyPath) {
                // this.tagshow = false
                // if (key == 8) {
                //     this.isnNavShow = !this.isnNavShow
                // }
                window.open(key,'_self')
            },
            toindex() {
                this.tagshow = true
                this.$router.push('/index')
            },
            querySearch(queryString, cb) {
                var restaurants = this.restaurants;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            createFilter(queryString) {
                return (restaurant) => {
                    return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            loadAll() {
                return [
                    {"value": "影赛"},
                    {"value": "采风"},
                    {"value": "培训"},
                ];
            },
            login() {
                this.$refs.passport.resetForm();
                this.$refs.passport.show = 'loginSmscode';
                this.$refs.passport.showPassport = true;
            },
            logout() {
                this.$refs.passport.userLogout();
            },
            passportLogin(data){
                let user = {
                    'user_id': data.user.id,
                    'nickname': data.user.nick_name,
                    'mobile': data.user.mobile,
                    'avatar': data.user.avatar
                };
                this.vuexUserLogin(user);
            },
            passportLogout(){
                this.vuexUserLogout();
                this.$router.push({
                    path: '/'
                })
            },
            toDown() {
                this.$router.push({
                    path: '/downloadAcr'
                })
            },
            toVip() {
                this.$wwwRouter.push('vip');
            }
        }
    }
</script>
<style scoped>
    .nav-box {
        width: auto;
        min-width: 1730px;
        height: 80px;
        background: #fff;
    }

    .nav-content {
        height: 100%;
        margin: 0 60px;
        display: flex;
        align-items: center;
        position: relative;
    }

    .nav-logo {
        width: 160px;
        height: 52px;
        background-image: url("../assets/images/logo.png");
    }

    .el-menu--horizontal > .el-menu-item {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .el-menu.el-menu--horizontal {
        border: none;
        margin-left: 20px;
    }

    .el-menu--horizontal > .el-menu-item {
        border-top: 5px solid transparent;
    }

    .el-menu--horizontal > .el-menu-item.is-active {
        /* border-color: #409EFF; */
        border-color: transparent;
        border-bottom: none;
    }

    .el-menu-item {
        text-align: center;
        position: relative;
    }

    .login-tag {
        width: 20px;
        height: 20px;
        background: red;
        position: absolute;
        top: 20px;
        left: -3px;
    }

    .registered-tag {
        width: 20px;
        height: 20px;
        background: red;
        position: absolute;
        top: 20px;
        left: -3px;
    }

    .sou-btn {
        width: 25px;
        height: 25px;
        background: url('../assets/images/sou2.png');
        position: absolute;
        right: 0;
        top: 18px;
    }

    .sou-btn:hover {
        background: url('../assets/images/sou1.png');
    }

    .login-box {
        margin-left: 75px;
    }

    .show-border {
        border-color: #fff !important;
    }

    .show-divnone {
        margin-left: 248px;
    }

    .linediv::before {
        content: '';
        width: 1px;
        height: 30px;
        background: #CECECE;
        position: absolute;
        left: -80px;
        top: 14px;
    }

    .is-active2 {
        border-color: #fff !important
    }

    .is-active2 >>> .el-submenu__title {
        border-color: #fff !important
    }

    .line-none {
        border-color: #fff !important
    }

    .el-button {
        padding: 12px 20px;
    }

    .nav-box {
        width: auto;
        height: 80px;
        background: #fff;
    }

    .nav-logo {
        width: 160px;
        height: 52px;
        background-image: url("../assets/images/logo.png");
        cursor: pointer;
    }

    .el-menu--horizontal > .el-menu-item {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .el-menu.el-menu--horizontal {
        border: none;
        margin-left: 60px;
    }

    .el-menu--horizontal > .el-menu-item {
        border-top: 5px solid transparent;
    }

    .el-menu--horizontal > .el-menu-item.is-active {
        /* border-color: #409eff; */
        border-color: transparent;
        border-bottom: none;
    }
    .el-menu--horizontal > .el-submenu.is-active >>> .el-submenu__title{
        border-color: transparent;
    }
    .el-menu--horizontal > .el-menu-item.cur {
        border-color: #409eff;
        border-bottom: none;
    }

    .search {
        margin-left: 20px;
    }

    .search .el-input {
        width: 250px;
    }

    .search >>> .el-input__inner {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    .search >>> .el-input-group__append {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: #409eff;
        border: 1px solid #409eff;
        color: #fff;
    }

    .login {
        margin-left: 20px;
        font-size: 14px;
        color: #0077fa;
        position: absolute;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .login .vip {
        width: 60px;
        padding:0;
        text-align: center;
        color: #e1a500;
        margin-right: 10px;
    }

    .login .vip img {
        width: 30px;
    }
    .login .download{
        color: #333;
    }

    .login a {
        color: #0077fa;
        display: inline-block;
        width: 30px;
        padding: 0 10px;
    }

    .login span i {
        font-style: normal;
    }

    .login .user-wrap {
        display: flex;
        align-items: center;
    }

    .login .user-wrap .username-wrap {
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #333;
    }

    .login .user-wrap .username-wrap:hover {
        color: #0077fa;
    }

    .login .user-wrap .username-wrap .username{
        max-width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .login .user-wrap .avatar {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        margin-right: 5px;
    }

    .nav-box /deep/ .dialog-login {
        width: 420px !important;
    }

    .nav-box /deep/ .dialog-login .el-dialog__headerbtn {
        font-size: 30px;
    }

    .dialog-login .hd {
        font-size: 24px;
        font-weight: 600;
        color: #333;
        margin: -30px 0 30px;
    }

    .dialog-login .bd-t {
        margin-bottom: 30px;
        text-align: right;
    }

    .dialog-login .main-button {
        width: 100%;
        padding: 16px 0;
    }

    .dialog-login .form-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
    }

    .form-bottom-l {
        display: flex;
        align-items: center;
    }

    .break-line {
        color: #999;
        margin: 0 8px;
    }

    .dialog-login .ipt-vcode {
        width: 240px;
    }

    .dialog-login .btn-vcode {
        width: 125px;
    }

    .dialog-login .img-vcode {
        width: 125px;
        height: 40px;
    }
</style>
