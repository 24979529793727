import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import Axios from './axios'

import '@/assets/css/reset.css'
import '@/assets/css/common.scss'

import echarts from "echarts";       

import ElementUI from 'element-ui'
Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$axios = Axios
Vue.prototype.$app_url = process.env.VUE_APP_URL;
Vue.prototype.$store = store
Vue.prototype.$echarts = echarts

Vue.prototype.$wwwRouter = {
  push: (res) => {
    window.location.href = process.env.VUE_APP_WWW_URL + 'dist/#/'+res;
  },
  open: (res) => {
    window.open(process.env.VUE_APP_WWW_URL + 'dist/#/'+res);
  }
}

// if(process.env.VUE_APP_ENV!='prod'){
//   console.log('env', process.env.VUE_APP_ENV);
//   console.log('url', process.env.VUE_APP_URL);
// }
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
